
.cleanse{
    width: 100vw;
    height: auto;
    padding:5vh 0;
    /* background: radial-gradient( rgba(246,157,60, .8), rgba(246,157,60, .2),rgba(246,157,60, 1)); */
    /* background: #FFFDDD; */
    /* background: #cca585; */
}
.encloseCleanseContent{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 5vh;
    border: 5px groove black;
    display: flex !important;
    flex-direction: column !important;
}

.cleanse .heading{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
}
.belowHeading{
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 3px solid #AE8625;
}
p{
    margin: 0;
    padding: 0;
}
.cleanseContent{ 
    padding-top: 3vh;
    display: flex;
    overflow: hidden;
}
.cleanseContent .encloseIframeContent{
    flex: 2 !important;
}
.cleanseContent .encloseIframeBenefits{
    flex: 1 !important;
}

.encloseIframeContent .addBenefit{
    width: fit-content !important;
    margin-left: auto !important;
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
    border-radius: 2px;
}
.encloseCleanseButtons{
    margin-top: 2vh;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
}
.changeButtons{
    display: flex;
}
.changeButtons button{
    font-size: .5em;
    padding: .5vh !important;
    border-radius: 0% !important;

}
.addBenefit{
    color: #fff !important;
    padding: 1vh;
    border: 1px solid #008080;
    background: #008080;

}
.cleanse p{ 
    padding: 2vh 0;
}
iframe{
    width: 100%;
    border-radius: 1vh;
    border: 1px solid #d3d3d3;    
}
.cleanseContent .encloseList{
    flex: .5;
    font-size: 1.5em;
}
.cleanseContent ul{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
   
}
.cleanseContent ul li:before{
    content:'✓';
    color: #B37A4C;
}
.cleanseContent ul li{
    line-height: 2em;
    opacity: .9;

}
.cleanse button{
    border: none;
    padding: 2vh;
    border-radius: 3vh;
    background: #B37A4C;
    display: flex;
    margin-left: auto;
    margin-right: 2em;
}
.meals{
    width: 100vw;
    height: auto;
}
.meals .wordSection{
    padding-bottom: 10vh !important;
}
.heading{
    font-size: 2em;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
}
.meals p{
    margin-top: 0;
    padding-top: 0;
}
.twiceencloseCleanse{
    width: 100%;
    padding: 10vh 0 1vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}
.twiceencloseCleanse .heading{
    line-height: 1em;
}
.twiceencloseCleanse .encloseCleanse{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.twiceencloseCleanse .heading{
    padding-bottom: 1vh;
    margin-bottom: 2vh;
}

.twiceencloseCleanse .encloseTwiceButton{
    display: flex;
    justify-content: flex-end;
    padding: 4vh 0;
}
.twiceencloseCleanse .encloseTwiceButton button{
    width: fit-content;
    margin-right: 5vh;
    padding: 2vh;
    border-radius: 2em;
    background: #cca585;
    border: none;
}

.encloseMyCleanses{
    display: flex;
    justify-content: space-between;
    padding: 10vh 0;
}
.encloseMyCleanses .fourteendayCleanse {
    flex:1;
    background: #ececec;
    border-radius: 1.5em;
}
.encloseMyCleanses .fourteendayCleanse .justBelowLink{
    width: 100% !important;
}
.encloseMyCleanses .twentyonedayCleanse{
    flex:1;
    background: rgb(1, 156, 1);
    margin-left: 5vh;
    border-radius: 1.5em;
}
.enclose14Cleanse{
    height: 60vh;
    background-position: center;
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-size:cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 3em;
    border-radius: 0 0 1.5em 1.5em !important;
}
.enclose14CleanseContents{
    padding: 4vh 2vh;
    border-radius: 1em;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    border: 1px solid rgba(255, 255, 255, 0.27);
}
.enclose14CleanseContents .duration{
    font-size: 5em;
    font-weight: 800;
}
.enclose14CleanseContents .someWording{
    font-size: 1.25em;
}
.cleanseIcon{
    width: fit-content;
    background: #fff;
    border-radius: 50%;
    padding: 2vh;
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000 !important;
    margin-top: -4vh;
}
.encloseMyCleanses .twentyonedayCleanse{
    flex: 2;
}

.myMeals{
    width: 100%;
    padding: 10vh 0;
    background: rgba(211,211,211, .1);
}
.myMeals .mealPhoto{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.myMeals .mealData{
    position: absolute;
    right:2%;
    bottom: 2%;
    background: #d3d3d3;
    padding: 5px;
    border-radius: 2px;
}
.myMeals .heading{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2vh;
}
.myMeals .belowHeading{
    width: 8%;
    border-bottom: 3px solid #AE8625;
}
.myMeals .mealPhoto img{
    width: 100%;
   
}
.myMeals .body{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2vh;
    opacity: 0.7;
    padding: 5vh 1vh;
    font-size: 1.5em;
}

.categoryHeading{
    margin: 0;
    padding: 0;
}
.individualMeal select{
    width: 50%;
    margin: 0 2vh;   
}
.individualMeal .mealDetails{
    width: 50%;
    margin: 0 2vh !important;
    border: none !important;
   
}
.bigScreenDifferentPlans button{
    background: none;
    width: 100%;
    border: none !important;
    color: #008080;
    padding: 1vh 0;
    text-align: left;
}
.healthyMind{
    width: 100%;
    padding: 5vh 0;
    background: #d3d3d3;
}
.healthyMind .heading{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1vh;
}
.healthyMind .contentSection{
    margin-top: 5vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 2vh 0;
}
.elaboration{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10vh 0;
    border-top: 1px solid  rgba(174, 134, 37, .5);
    border-bottom: 1px solid  rgba(174, 134, 37, .5);
    position: relative;
}
.elaboration:before{
    content: "";
    background-image: url("../img/who.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: .2;
}
.elaboration .encloseElaboration{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    justify-content: space-between;
    position: relative;
}
.encloseElaboration .encloseMyButton{
    display: flex;
    justify-content: flex-end !important;
}
.encloseMyButton button{
    margin-right: 2vh;
}
.encloseMyButton .rub{
    border: none;
    background: red !important;
}
.encloseElaboration .leftElaboration{
    flex: 1 !important;
    display: block;
}
.encloseElaboration .rightElaboration{
    flex: 1 !important;
    display: block;
    margin: auto;
}
.elaboration .encloseElaboration .encloseHeading{
    font-size: 2em;
    line-height: 1em;
    padding-bottom: 2vh;
    color:  rgba(174, 134, 37, 1);
    text-align: center;
    font-weight: 100;
    text-transform: capitalize;
}
.elaboration .body{
    font-size: 1.5em;
    line-height: 1.25em;
    opacity: .7;
}

.elaboration .encloseElaboration .leftElaboration .encloseLeftElaboration{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
} 

.contentSection .contentImage{
    flex: 1;
}
.contentImage img{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #d3d3d3;
    border-radius: 2vh;
}
.contentSection .healthyMindSection{
    flex: .5;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh 1vh;
   
}
.contentSection .healthyMindSection .specialButton, .encloseEmptyCart .specialButton {
    margin: 3vh 0;
    padding: 2vh 1vh;
    border: none;
    background: linear-gradient(45deg, rgba(62,28,0, .8), rgba(246,157,60, 1), rgba(246,157,60, .8),  rgba(246,157,60, 1));
    text-align: center;
    font-size: small;
    text-transform: uppercase;
}
.contentSection .healthyMindSection button:hover{
    background: rgba(246,157,60, 1);
}
.subHeading{
    font-size: 1.5em !important;
    padding-bottom: 2vh;
    font-weight: 600;

}
.subBody{
    padding: 2vh 0;
    font-size: 1.25em;
    line-height: 1.25em;
    opacity: .8;
}
.mentor{
    width: 100% !important;
    max-width: 100% !important;
    padding: 10vh 0;
}

.mentor .heading{
    padding-bottom: 2vh;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.mentor .belowHeading{
    margin-bottom: 10vh;

}
.thementor{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 60vh;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 2em;
    padding: 8vh 0;
}

.mentor .smallScreenCoach{
    display: none !important;
}

.thementor .coachDescription{
    flex:1;
    height: inherit;
    margin-left: auto;
    display: flex;
    align-items: center;
    line-height: 2em;
    background: linear-gradient(45deg, rgba(62,28,0, .8), rgba(246,157,60, 1), rgba(246,157,60, .8),  rgba(246,157,60, 1));
    margin-right: -2vh;
    border-radius: 2em 0 0 2em;
}
.thementor .coachDescription p{
    padding: 1vh 3vh;
    font-size: 1.25em;
}
.thementor .coachImage{
    flex:1;
    display: flex;
    align-items: center;
}
.thementor .coachImage img{
    width: 100%;
    background: #fff;
    border-radius: 2em;
    padding: 12vh 0;
    border: 1px solid rgba(246,157,60, .4);

}
.eventSection{
    width: 100%;
    min-height: 70vh;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.eventSection p{
    font-size: 2em;
    margin: 2vh 0;
}
.eventSection button{
    font-size: 1.25em;
    margin: 1vh;
    padding: 2vh;
    color: #008080;
    border: none;

}
.myReviews{
    width: 100%;
    padding: 10vh 0;
    background: #fff;
    position: relative;
}
.myReviews:before{
    content: "";
    background-image: url("../img/whisper.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.myReviews .encloseHomeReviews{
    position: relative;
}

.myReviews .encloseReviewTitle{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.myReviews .reviewHeader{
    width: fit-content;
    padding-bottom: 2vh;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 600;
}
.myReviews .belowHeading{
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 3px solid #AE8625;
}
.myReviews .encloseMyReviews{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    padding: 10vh 0;
}

.myReviews .encloseMyReviews .homepageReviews{
    width: 90%;
}
.myReviews .encloseMyReviews .reviewArrow{
    flex:1;
    font-size: 2em;
}
.weHelp{
    width: 100vw;
    padding: 20vh 0;
    display: flex;
    flex-direction: column; 
    background-color: #FFFDFA;
    border-bottom: 1px solid #AE8625;
    border-top: 1px solid #AE8625;
}
.weHelp .encloseHelpSection{
    display: flex;
}
.weHelp .encloseHelpSection .leftHelp{
    flex:2;
    display: flex;
    min-height: 60vh;
}
.weHelp .encloseHelpSection .leftHelp .mainHelpLeft{
    flex:1;
    align-self: flex-end;
    height: 40vh;
    background: rgba(246,157,60, 1);
}
.weHelp .encloseHelpSection .leftHelp .mainHelpRight{
    flex:1;
    
}
.weHelp .encloseHelpSection .leftHelp .mainHelpRight img{
    width: 100%;
    margin-left: -20vh;
    margin-top: -10vh;
    border-radius: 2px;
}
.weHelp .encloseHelpSection .rightHelp{
    flex:3;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.weHelp .encloseHelpSection .rightHelp .rightHelpHeading{
    width: 90%;
    margin-right: auto;
    font-size: 1.8em;
    line-height: 1.25em;
    text-align: center;
    padding-bottom: 10vh;
}
.weHelp .encloseHelpSection .rightHelp .belowText{
    width: 20%;
    border-top: 5px solid #AE8625;
    margin: 0 auto 10vh auto;
}
.weHelp .encloseHelpSection .rightHelp .rightHelpBody{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5em;
    line-height: 1.25em;
    display: flex;
    flex-direction: column;
}
.rightHelp .helpClarityCall{
    min-width: fit-content;
    justify-self: center;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25em;
    border: none;
    border-radius: 4px;
    border: 1px solid #AE8625;
    padding: 2vh;
}
.rightHelp .helpClarityCall:hover{
    background: #AE8625;
}
.rightHelp .helpClarityInfo{
    width: 90%;
    text-align: center;

    opacity: .6;
    padding: 2vh 0;
    font-weight: 500;
    line-height: 1em;
}
.blogSection{
    width: 100%;
    padding: 10vh 0;
}
.blogSection .encloseEntireBlog{
    padding-top: 8vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.blogSection .encloseEntireBlog .sideBar{
    flex:1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.blogSection .encloseEntireBlog .sideBar .homeArticle{
    border-bottom: 1px solid rgba(174, 134, 37, .6);
    padding: 3vh 0;
    width: 95%;
    margin-left: auto;
    margin-right: auto;    
}
.blogSection .encloseEntireBlog .sideBar .noborder{
    padding: 3vh 0;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.blogSection .encloseEntireBlog .sideBar .homeArticle p, .blogSection .encloseEntireBlog .sideBar p{
    font-size: 1.5em;
    opacity: .8;
    line-height: 1.5;
    text-transform: uppercase;
}

.encloseBlogSection{
    flex:3;
    display: flex;
    border-right: 1px solid rgba(174, 134, 37, .4);
}

.blogSection .blogLeft{
    flex:.5;
}
.blogLeft img{
    width: 90%;
    display: block;
    margin: 0 auto;
}
.blogSection .title{
    font-size: 2em;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 2vh;
}
.blogLeft .subTitle{
    font-size: 1.5em;
    font-weight: 600;
    color: #008080;
    text-align: center;
}
.blogSection .blogRight{
    flex:1;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 2vh;
}
.blogRight .vitaminBody{
    flex: 1;
}
.blogRight .vitaminFoods{
    flex:2;
}
.blogRight button{
    flex: .5;
    border: none;
    background: none;
    text-align: left;
}
.subTitle{
    line-height: 2em;
    font-weight: 800;
    font-family: 'Noto Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
    
    .containMealData{
        margin-left: 2vh;
        margin-right: 2vh;
        margin-bottom: 2vh;
        padding: 2vh;
        background: #fff;
        border-radius: 3vh;
        
    }
    .containMealData p{
        margin: 0;
        padding: 0;
    }
    .containMealData .title{
        font-weight: 500;
        font-size: 1.25em;
        
    }
    .containMealData button{
        border: none;
        background: none;
        color: #B37A4C;
        text-transform: lowercase;
    }
    
}
