.firstHomeSection{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    display: flex;
    background-repeat: none;
    background-size: contain;
    background: linear-gradient(45deg, rgba(62,28,0, .8), rgba(246,157,60, 1), rgba(246,157,60, .8),  rgba(246,157,60, 1));
}
.firstHomeSection .rightSection{
    flex:2;
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.firstHomeSection .rightSection .encloseHeading, .firstHomeSection .rightSection .encloseBody{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.smWholeness{
    display: none;
}
.firstHomeSection .rightSection .heading{
    font-size: 4em;  
    font-weight: 800;
    line-height: 1em;
    font-family: 'Gothic A1', sans-serif;
    text-transform: capitalize !important;
}
.firstHomeSection .rightSection .body{
    padding: 6vh 0;
    font-size: 1.5em;
    opacity: .7;
}
.firstHomeSection .rightSection div{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: left; 

}
.firstHomeSection .rightSection .encloseHeading .encloseMyButton,
.firstHomeSection .rightSection .encloseBody .encloseMyButton{
    display: flex;
    justify-content: flex-end;
}
.firstHomeSection button{
    border: none;
    font-size: 1em;
    background: #FFFDDD;
    padding: 2vh;  
}
.firstHomeSection .addBenefit{
    background: #008080;
    font-size: 2vh;
    border-radius: 5px;
}
.firstHomeSection .leftSection{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid black; */
}
.firstHomeSection .leftSection img{
    width: 100%;
}
@media only screen and (max-width: 992px){
    .firstHomeSection .rightSection{
        padding-top: 30vh;
        padding-bottom: 5vh;
    }
    .firstHomeSection .rightSection .heading{
        font-size:3em;
    }
    .firstHomeSection .rightSection .body{
        font-size: 1.5em;
    }

}
@media only screen and (max-width: 768px){
    .firstHomeSection{
        padding-top: 2vh;
    }
    .firstHomeSection .rightSection{
        flex:1;
        font-size: .7em;
    }
    .firstHomeSection .leftSection{
        flex:1;
    }

}
