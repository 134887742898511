.program{
    width: 100%;
}
.encloseProgram{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
fieldset{
    width: 40%;
    padding: 5vh;
    border-radius: 1vh;
    border: .2px solid #000;

}

fieldset legend{
    font-size: 1.5em;
    color: #000;
}
fieldset .individualProgram{
    border-radius: 2vh;
    background: rgba(246,157,60, .8);
    font-size: 1.25em;
    margin: 2vh 0;
    display: flex;
    flex-direction: column;
   overflow: hidden;

}
fieldset .individualProgram .programBody{
    display: flex;
    align-items: center;
    padding: 1em;
}
fieldset .individualProgram button{
    border: none;
    background: #7B3F00;
    text-transform: uppercase;
    padding: 1vh;
    color: aliceblue;
}

fieldset .individualProgram input{
    width: 20px;
    height: 20px;
}
fieldset .programButtons{
    display: flex;
    justify-content: flex-end;
    padding: 1vh;
}
fieldset .programButtons button{
    padding: 1vh;
    text-transform: uppercase;
    margin-right: 1vh;
}
@media only screen and (max-width: 400px){
    .encloseProgram{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    fieldset{
        width: 90% !important;
    }
    fieldset legend{
        font-size: 1em;
        text-align: center;
    }
}