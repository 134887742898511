.login{
    width: 100%;
    margin:0 !important;
}
.encloseLogin{
    width: 30% !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 5vh auto;
    border-right: 2px solid #d3d3d3 !important;
}

.encloseImage{
    width:100%;
    background: #fffdc6;
}
.encloseImage2{
    width: 100%;
    background: #fff;
}
.encloseImage img, .encloseImage2 img{
    width: 50%;
    display: block;
    margin: 0 auto;
}
.encloseLogin .loginContents{
    width: 100%;
}
.loginContents2{
    background: #fff;
}
.encloseLogin .loginContents p{
    margin: 0;
    text-transform: uppercase;
    font-size: 1em;
    color: #008080;
    text-align: center;
    border-top: 1px solid #d3d3d3;
}
.encloseLogin .loginContents form{
    width: 100%;
    padding: 4vh 0;
} 
.encloseLogin .loginContents form .individualItem{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding:2vh 0;
    font-size: 1.25em;
}
.encloseLogin .loginContents form .individualItemButton{
   display: flex;
   justify-content: center;
}
.encloseLogin .loginContents form .individualItemButton button{
    border: none;
    width: 30%;
    min-width: fit-content;
    padding: 2vh;
    border-radius: 2vh;
    background: #008080;
    text-transform: uppercase;
    
}
.encloseLogin .loginContents form .individualItem input{
    width:100%;
    height: 5vh;
    border: none;
    border: 1px solid black;
}

