.fillSpace2{
    height: 10vh;
    width:100%;
}
.blogHeader{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;
    font-size: 2em;
    padding: 2vh 0;
    color: #008080;
    padding-top: 10vh;
    padding-bottom: 5vh;
}
.mainBlog .encloseArticles{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10vh;
}
.mainBlog .encloseArticles .article{
    padding: 4vh 0;
    border-top: 1px solid rgba(246,157,60, .8);
}
.mainBlog .encloseArticles .article .articleHeader{
    text-transform: uppercase;
    font-size: 1.5em;

}
.mainBlog .encloseArticles .article .articleBody{
    opacity: .8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    padding: 1vh 0;
}
.mainBlog .encloseArticles .article .articleOptions{
    width: 100%;
    display: flex;
}
.mainBlog .encloseArticles .article .articleOptions .finalLeftSection{
    flex:.5;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: 500 !important;
    padding: 1vh 0 !important;
}
.mainBlog .encloseArticles .article .articleOptions .finalLeftSection .firstIcon{
    margin: 0 2vh !important;
}
.mainBlog .encloseArticles .article .articleOptions .finalRightSection{
    flex:1;
    display: flex;
    justify-content: flex-end !important;
}
.mainBlog .encloseArticles .article .articleOptions .finalRightSection p{
    opacity: .8;
    font-size: .7em;
}
.article .modifyBlog{
    display: flex;
    justify-content: center;
    padding: 1vh;
    font-size: 2em;
}
.article .modifyBlog button{
    margin-right: 1vh;
    padding: 1vh;
    display: flex;
    align-items: center !important;
    font-size: .4em;
    background: transparent;
    border-radius: .5vh;
    border: none;
    border: 1px solid black;
    text-transform: uppercase;
    color: #000;
}
button .blogIcons{
    color: #008080;
    font-size: 2em;
}
.encloseAddButton{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
    padding: 1vh;
}
.encloseAddButton button{
    display: flex;
    align-items: center !important;
    font-size: 1em;
    border: 1px solid #d3d3d3;
    padding: 1vh 2vh;
    background: transparent;
}

@media only screen and (max-width: 400px){
    .blogHeader{
        text-align: start;
        font-size: 1.25em;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 2vh 0;
        opacity: .7;
    }
    
    .mainBlog .encloseArticles{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 2vh 0;
      
    }
    .encloseArticles .articleHeader{
        text-transform: uppercase;
        font-weight: 600;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .encloseArticles .article{
        padding: 2vh 0;
        border-top: 1px solid #d3d3d3;
    }
    .encloseArticles .articleBody{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        opacity: .6;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap; 
        padding-top: 1vh;
        
        
    }

}
