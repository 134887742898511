.cart{
    width: 100%;
}
.encloseCart{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}
.encloseEmptyCart{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    color: #008080;
    font-size: 1.25em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    background: #faf9f9;

}
.encloseEmptyCart .specialButton{
    display: flex;
    align-items: center;
    border-radius: 5vh;
    padding: 3vh !important;
    font-size: 1em !important;
}
.emptyCartIcon{
    font-size: 6em;
    padding: 5vh;
}
.encloseCartMessage{
    width: 100%;
    /* background: #ebebeb; */
    padding: 2vh;
    border-radius: 2vh;
    text-align: center;
    opacity: .5;
}

.encloseMyCart{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    border-top: 10px solid rgba(246,157,60, .8);
    border-left: 1px solid rgba(246,157,60, .8);
    border-right: 1px solid rgba(246,157,60, .8);
    border-bottom: 1px solid rgba(246,157,60, .8);
}
.encloseCart .cartTitle{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3vh;
    padding-bottom: 5vh;
    font-size: 2em;
    font-weight: 400;
    text-align: center;
}
.encloseCart .headerSection{
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgba(246,157,60, .8);
    text-align: center;
    padding: 1vh 0;
}
.encloseCart .itemSection{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2vh 0;
}
.encloseCart .itemSection .itemImage{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itemImage img{
    width: 10%;

}
.itemSection p{
    flex:1;
    display: flex;
    justify-content: center;
}
.headerSection p{
    flex:1;
    font-weight: 600;
    font-size: 1.25em;
}
.bottomSection{
    width: 100%;
    display: flex;
    flex-direction: column;  
    justify-content: flex-end; 
    align-items: flex-end;
}
.encloseBottomSection{
    width: 30%;
    display: flex;
    flex-direction: column;  
    justify-content: flex-end; 
    align-items: flex-end;
    margin-top: 2vh;
}
.encloseBottomSection button{
    padding: 1vh 2vh;
    background-color: rgba(246,157,60, .8);;
    border: none;
    border-radius: 2vh;
    margin-right: 1vh;
    text-transform: uppercase;
}
.encloseBottomSection .email{
    opacity: .8;
    margin: 0;
    font-size: .8em;
}
.totalCharge{
    font-size: 1.25em;
    font-weight: 600;
    margin: 0;
    color:#008080;
    margin-top: 2vh;

}
.paymentProgramme{
    width: 98vw;
    margin-left: auto;
    margin-right: auto;
    height: 10vh;
    display: flex;
    flex-wrap: nowrap;
    margin-top:  auto;
    align-items: flex-end;
}
.paymentProgramme .encloseMethod{
    flex: 1;
}
.paymentProgramme .encloseMethod img{
    width: 100%;
    max-height: 10vh;
    overflow: hidden;
}
@media only screen and (max-width: 400px){
    .encloseCart{
        padding-top: 10vh !important;
    }
    .cartTitle{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2vh;
        font-weight: 800;
        opacity: .8;
        font-size: 1.25em;
    }
    .encloseMyCart{
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
    }
    .encloseMyCart .headerSection{
        width: 100%;    
    }
    .headerSection p{
        flex:1;
        font-weight: 600;
        font-size: 1em;
    }
    .itemSection p{
        font-size: .9em;
    }
    .itemImage img{
        width: 20%;
        display: block;
        margin: 0 auto;

    }
}
