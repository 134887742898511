.footer{
    width: 100vw;
    padding: 4vh 0;
    /* background: #fffdc6; */
    /* background: #fcfbe1; */
    background: linear-gradient(to right, rgba(255, 255, 255, .8), rgba(255, 255, 255, .1));
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.2)
}
.footerEnclosing{
    display: flex;
    justify-content: center;
}
.footerEnclosing .a{
    display: flex;
    flex-direction: column;
    line-height: 1.8em;
    text-align: left;
    font-size: 1.25em;
}
.footerEnclosing ul{
    list-style: none;
}
.footerEnclosing ul li{
    font-weight: 500;
    text-transform: uppercase;
}
.footerEnclosing ul li:focus{
    color: #008080;
}

.encloseSecondFooter{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.encloseSecondFooter .leftSide{
    flex:.8;
}
.encloseSecondFooter .leftSide img{
    width: 80%;
    height: auto;

}
.encloseSecondFooter .rightSide{
    flex:2;
    padding: 4vh 0;
    display: flex;
}
.encloseSecondFooter .rightSide .first{
    flex: 1;
    justify-content: flex-start;
}
.encloseSecondFooter .rightSide .footerEnclosing{
    flex:.5;
}
.encloseSecondFooter .rightSide .subscribeSection{
    padding: 4vh 4vh 8vh 8vh;
    margin: 2vh;
    border-radius: 2vh;
    text-align: start;
    /* background: linear-gradient(to top right, rgba(174, 134, 37, 1), rgba(174, 134, 37, 0.8)); */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(174, 134, 37, .6);
}
.encloseSecondFooter .rightSide .subscribeSection p{
    padding-bottom: 1vh;
    color: #000;
}
.encloseSecondFooter .rightSide .subscribeSection form{
    width: 90%;
    display: flex;
    justify-content: space-around;
}
.encloseSecondFooter .rightSide .subscribeSection form .emailSection{
    flex: 1;
    font-size: 4vh;
}
.encloseSecondFooter .rightSide .subscribeSection form .buttonSection{
    flex: .5;
    font-size: 4vh;
    background: transparent;
}
.encloseSecondFooter .rightSide .subscribeSection form .buttonSection .subscribeButton{
    border: none;
    background: linear-gradient(45deg, rgba(62,28,0, .8), rgba(246,157,60, 1), rgba(246,157,60, .8),  rgba(246,157,60, 1));
}
.encloseSecondFooter .rightSide .subscribeSection form .buttonSection .subscribeButton:hover{
    background: rgba(246,157,60, 1);
}
.encloseSecondFooter .rightSide .subscribeSection input{
    width: 90%;
    height: 100%;
    border: 1px solid #000;
}

.encloseSecondFooter .rightSide .socialSection{
    margin: 2vh;
    padding: 4vh;
}
.encloseSecondFooter .rightSide .socialSection p{
    padding-bottom: 1vh;
    text-align: start;
    font-size: 2em;

}
.encloseSecondFooter .rightSide .socialSection .socials{
    font-size: 1.5em;
    color: #008080;
    display: flex;
    justify-content: space-around;
}
input:focus{
    outline: none;
}

