.scriptEnclosure{
    max-width: 100vw;
    height: auto;
    width: 100vw;
}

.encloseScriptContent{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.encloseBlogScript{
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    /* padding: 5vh; */
    /* margin-top: 2vh; */

}
.encloseBlogScript .script{
    caret-color: transparent;
    width: 100%;
    min-height: 90vh;
    max-height: 80vh !important;
    height: auto !important;
    font-size: 1.5em;
    padding: .5vh;
    resize: none;
    background: transparent;

    background-attachment: local;
    background-image:
        linear-gradient(to right, white 10px, transparent 10px),
        linear-gradient(to left, white 10px, transparent 10px),
        repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
    line-height: 31px;
    padding: 8px 10px;
}
.encloseBlogHeader{
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    /* padding: 1vh; */
    margin-bottom: 5vh;
    margin-top: 2vh;
}
.encloseBlogHeader form{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1vh;

}
.encloseBlogHeader form label{
    text-transform: uppercase;
    padding: 1vh;
}
.encloseBlogHeader form input{
    font-size: 2vh;
    border: none;
    border-bottom: 3px solid #008080;
}
.encloseSubmitButton{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    padding: 1vh;
}
.encloseSubmitButton button{
    margin-right: 1vh;
    padding: 1vh;
    font-size: 1em;
    text-transform: uppercase;
}