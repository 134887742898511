
.container {
    position: relative;
    padding: 8px 8px 32px 8px;
    background: #008080;
}

.container:after {
    background:
    linear-gradient(-45deg, #FFFDDD 16px, transparent 0), linear-gradient(45deg, #FFFDDD 16px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 32px;
}
