.dropdown{
    max-width: 100%;
    background-color: #cca585;
    position: relative;
    
}

.dropdown ul{
    position: absolute;
    width: 100%;
    border-radius:0 0 3vh 3vh;
    list-style: none;
    line-height: 2em;
    background-color: #FFFDDD;
    margin: 0 !important;
    padding-left: 0;
    overflow: hidden;
    z-index: 300 !important;

}
.dropdown ul li{
    width: 100%;
    opacity: .6;
    padding-left: 5vh;
}
