@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Josefin+Sans:wght@700&family=Lato:ital,wght@0,300;0,400;1,300&family=Lora&family=Montserrat:wght@500;600&family=Noticia+Text:wght@700&family=Open+Sans&family=Orbitron:wght@600&family=PT+Sans+Narrow&family=Roboto+Condensed:wght@300&family=Roboto:wght@700;900&family=Ropa+Sans&family=Rubik&family=Slabo+27px&display=swap');

.encloseNavbar{
    padding-bottom: 1vh !important;
}
.secondNavbar{
    width: 100%;
    background: linear-gradient(to right, rgba(245,235,192, 1),  rgba(245,235,192, .8), rgba(245,235,192, 1));
    background: linear-gradient(to right, rgba(255, 255, 255, .8), rgba(255, 255, 255, .1));
    /* background-color: #BC9E82; */    
    z-index: 1000;

}
.middleSidesixhundred{
    display: none;
}
.navbar{
    width: 100%;
    z-index: 1000;
}
.encloseTop{
    width: 80%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    font-family: 'Slabo 27px', serif;
    font-size: 1em;
}
.encloseTop .leftSide{
    flex: .8;
}
.leftSide input[type="file"]::file-selector-button {
    background-color: #008080;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.leftSide img{
    width: 90%;
    height: auto;
    float: left;
    border-radius: 10%;
    max-height: 20vh;
}
.leftSide p, .middleSide ul, .rightSide p{
    margin: 0;
    padding: 0;
}
.leftSide .description{
    font-size: .6em;
    color: #B37A4C;
}
.leftSide button{
    margin-bottom: 1vh;
}
.encloseTop .rightSide{
    flex:1;
}
.encloseTop .aside{
    flex:3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.encloseTop .aside button{
    margin-top: 2vh;
}
.encloseTop .aside p{
    font-size: 2em;
}
.middleSide{
    width: 100%;
    padding: 1vh 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.middleSide ul{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    display: flex;
    justify-content:space-around;
    font-family: 'Lora', serif;
    font-size: 1em;
    text-transform: uppercase;
    opacity: .8;
    cursor: pointer;
}
.middleSide ul li{
    padding: 1vh;
}
.middleSide ul a:hover{
    color: #AE8625 !important;
}
.rightSide{
    flex:.5;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.rightSide button{
    border: none;
    border-radius: 50%;
    background: #fff;
    padding: 1vh 5vh !important;
    border: 1px solid #AE8625;
    font-size: large;
    color: #008080;
}

/* .rightSide button:hover{
    background-color: #AE8625;
} */
.rightSide .notSmall{
    padding: 1.5vh;
    border-radius: 30vh;
}
.rightSide .small, .rightSide .cartIcon, .rightSide .moreIcon{
    display: none;
}
a{
    text-decoration: none;
    color: black;
    padding: 0 1vh;
    
}

