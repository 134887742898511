.reviews{
    width: 100%;   
}
.encloseReviews{
    padding: 4vh 0;
    width: 100%;
}

.encloseReviewInfo{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: transparent;
}
.triangleLeft{
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 5px solid #000; 
}
.encloseReviewInfo .encloseComment{
    flex:4;
    display: flex;
}
.encloseReviewInfo .encloseImage{
    flex:.5;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 1.5em;
    border: 1px solid #000;
    background: transparent;
}
.encloseReviewInfo .encloseText{
    border: 1px solid #000;
    padding: .5em;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

}
.encloseReviewInfo .encloseText .review{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.encloseReviewInfo .encloseText .bottomsection{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1vh;
    display: flex;
    align-content: center;
}
.encloseReviewInfo .encloseText .bottomsection .reviewer{
    flex: 1;
    opacity: .6;
    font-weight: 500;
}
.encloseReviewInfo .encloseText .bottomsection .datereviewed{
    font-size: .8em;
}