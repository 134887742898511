.coaching{
    width: 80%;
    min-height: 80vh;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coaching p{
    font-size: 2em;
    color: #AE8625;
    font-weight: 300;
}
.coaching .love{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-areas:
    'vertical transparent transparent transparent transparent' 
    'horizontal horizontal horizontal horizontal horizontal';

  padding: 10px;
   
}
.coaching .love .hStrip{
    grid-area: transparent;
    display: flex;
    align-items: flex-end;
    padding-bottom: 4vh;

}
.coaching .love .hStrip .encloseHStrip{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
}
.coaching .love .verticalStrip{
    grid-area: vertical;
    background-color: #000;
    text-align: center;
    padding: 120px 0;
    font-size: 30px;

}
.coaching .love .horizontalStrip{
    grid-area: horizontal;
    background: #000;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}

.coaching .career{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-areas:
    'verticala verticala verticala verticala verticala'
    'transparenta tuppla tuppla tuppla tuppla' 
    'horizontala horizontala horizontala horizontala horizontala';

    padding: 10px;

}
.career .topStrip{
    grid-area: verticala;
    background: #000;
    text-align: center;
    padding: 5px 0;
    font-size: 30px;
}
.career .middleStrip{
    grid-area: transparenta;
    background: #000;
    text-align: center;
    padding: 120px 0;
    font-size: 30px;
}
.career .kmiddleStrip{
    grid-area: tuppla;
    display: flex;
    align-items: center;
}
.career .kmiddleStrip .encloseMiddleStrip{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   
}
.career .bottomStrip{
    grid-area: horizontala;
    background: #000;
    text-align: center;
    padding: 5px 0;
    font-size: 30px;
}
.coaching .finance{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-areas: 
    'binance binance binance binance binance'
    'france trans trans trans trans'
    'dance dance dance dance transa'
    'strip crude crude crude crude';
    padding: 10px;
}
.finance .binance1{
    grid-area: binance;
    background: #000;
    text-align: center;
    padding: 5px 0;
    font-size: 20px;
}
.finance .binance2{
    grid-area: france;
    background: #000;
    text-align: center;
    padding: 5px 0;
    font-size: 40px;
    
}
.finance .binance3{
    grid-area: trans;
    background: #fff;
}
.finance .binance33{
    grid-area: transa;
}
.finance .binance333{
    grid-area: crude;
    display: flex;
    align-items: flex-end;    
}
.finance .binance4{
    grid-area: dance;
    background: #000;
    text-align: center;
    padding: 5px 0;
    font-size: 30px;
}
.finance .binance5{
    grid-area: strip;
    background: #000;
    text-align: center;
    padding: 60px 0;
    font-size: 20px;
}
.finance .encloseBinance333{
    width: 90%;
    margin-right: auto;
    margin-left: auto;
   
}
.steps{
    border-top: 1px solid #d3d3d3;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset; */
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    margin-top: 10vh;
    padding-bottom: 8vh;
    padding-top: 2vh;
    padding-left: 0;
    padding-right: 0;
}
.encloseStrip{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.encloseStrip .heading{
    font-size: 2em;
    text-align: left;
}
.encloseStrip .stripBody{
    opacity: .8;
    padding: 2vh 0;
}

@media only screen and (max-width: 400px) {
    .coaching .love{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 1px !important;
    }
    .coaching .career{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .coaching .finance{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .finance .binance2{
        padding: 40px 0;
    }
    .finance .binance5{
        padding: 10px 0 !important;
    }
    
    

}