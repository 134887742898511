.changeDialog{
    width: 100vw;
    height:100vh;
    position: fixed;
    z-index:400;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(255,255,255,0.5); ;

}
.changeDialogContent{
    width:30%;
    height:60%;
    display: flex !important;
    flex-direction: column !important;
}

.changeDialogContent ul{
    list-style:none;
    width: 100%;
    background: white;
    padding: 0px 0px 0 0px;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
}

.changeDialogContent ul li{
    width: 100%;
    border-bottom: .2px solid #d3d3d3;
    padding: 2px 0px;
}
.changeDialog .submissionSection{
    width:98%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.changeDialog .submissionSection .cancel{
    float:left
}
.changeDialog .submissionSection .save{
    float: right;
}