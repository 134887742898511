.contact{
    width: 100vw;
}
.encloseContact{
    width: 60%;
    margin: 10vh auto;
    min-height: 80vh !important;
    background: linear-gradient(45deg, rgba(62,28,0, .8), rgba(246,157,60, 1), rgba(246,157,60, .8),  rgba(246,157,60, 1));
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
}
.encloseContact .contactIcon{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(246,157,60, 1);
    background: #fff;
    color: black;
}
.contactIcon .contactSth{
    font-size: 10em;
    margin: 0;
    padding: 0;
    color: black;
}
.encloseContact .contactForm{
    flex:3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.encloseContact .contactForm .headerT{
    opacity: .6;
    padding: 2vh 0;
}

.contactForm form{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.contactForm .formInput{
    width: 80%;
    margin: 0 auto;
    padding: 1vh 0;
}
.encloseContact .contactForm label{
    opacity: .8;
    padding: 2vh 0;
}
.encloseContact .contactForm textarea{
    width:100%;
    border: none;
    height: 20vh;
}
.encloseContact .contactForm input{
    border: none;
    height: 5vh;
    width: 100%;
}
.encloseContact .contactForm .formButton{
    width: 30%;
    display: block;
    margin: 0 auto;
    padding: 1vh 0;
}
@media only screen and (max-width: 400px) {
    .encloseContact{
        padding: 2vh auto;
        width: 100%;
        flex-direction: column !important;
        margin-top: 10vh;
        border: none !important;
        border-radius: 0% !important;
        background: transparent !important;
    }
    .encloseContact .contactIcon{
        border:none;
        background: transparent !important;
    }
    .encloseContact .contactIcon .contactSth{
        font-size: 5em;
        padding: 4vh 0;
    }
    .encloseContact .contactForm{
        padding: 2vh 0;
    }
    .encloseContact .contactForm form .formInput{
        padding: 1vh 0;
    }
    .encloseContact .contactForm form .formInput input{
        border: 1px solid #d3d3d3;
    }
    .encloseContact .contactForm form .formInput textarea{
        border: 1px solid #d3d3d3;
    }
    .encloseContact .headerT{
        opacity:.6;
        font-weight: 100 !important;
        font-size: 12px;
    }
}
