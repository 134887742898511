.blog{
    width: 100%;
}
.encloseBlog{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
.encloseBlog .firstSection{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    color: #008080;
    display: flex;
    align-items: center;
    padding-top: 3vh;
}
.blogHeading{
    font-size: 1.5em;
    color: #008080;
}
.encloseBlog .firstSection button{
    width: fit-content;
    height: fit-content;
    border: none !important;
    text-align: left;
    margin-left: 0 !important;
    background: transparent;
}
.encloseBlog .firstSection p{
    text-transform: capitalize;
    margin-left: 1vh;
}

.encloseBlog .secondSection{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex:6;
    display: flex;

}
.encloseBlog .secondSection .blogBody{
    flex: 3;
    display: flex;
    align-items: center;

}

.blogBody .encloseBody{
    width: 90%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
}
.blogBody .encloseMessage{
    line-height: 1.5em;
    opacity: .6;
    font-size: 1.2em;
}
.encloseBody .encloseTitle{
    text-align: center;
    font-size: 2em;
    font-weight: 500;
    padding-bottom: 2vh;
   

}
.encloseBody .encloseTitle p{
    margin: 0;
}
.encloseBlog .secondSection .blogContents{
    flex: 1;
}
.blogContents .encloseBlogContents{
    width: 100%;
    height: 30vh;
    padding: 2vh 0;
}

.blogContents .encloseBlogAdvertisements{
    width: 100%;
    border: 1px solid #d3d3d3;
    font-size: 3vh;
    text-transform: uppercase;
    text-align: center;
    margin: 2vh 0;
    margin-left: auto;
    height: 35vh;
}
.encloseBlog .secondSection .blogContents fieldset{
    width: 90%;
    margin-left: auto;
    border: 1px solid #008080;
    padding-left: 0 !important;
    text-align: center;
}
.encloseBlog .secondSection .blogContents fieldset legend{
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 400;
}
.encloseBlog .secondSection .blogContents fieldset ul{
    margin: 0;
    padding: 0;
    list-style: none;
    width: 90%;
    padding-left: 2vh;
    text-align: left;
}
.encloseBlog .thirdSection{
    width: 100%;
    flex:2;
    border-top: 1px solid rgba(246,157,60, .8);
    padding: 2vh 0;
}
.thirdSection form{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 3vh 0;
}
.thirdSection form .formInput{
    flex:2;
}
.thirdSection form .formInput input{
    width: 100%;
    height: 5vh;
    border-radius: 1vh;
    border: 1px solid #008080;
}
.thirdSection form .formInputButton{
    flex:1;
    display: flex;
    align-content: center;
    justify-content: center;
}
.thirdSection form .formInputButton input{
    width: 50%;
    background-color: #fff;
    border: none;
    border: 1px solid #008080;
}
.thirdSection .existingComments{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.thirdSection .existingComments p{
    margin: 0;
}
.thirdSection .existingComments .responses{
    font-size: .8em;
    color: #008080;
    font-weight: 600;
    padding-bottom: 1vh;
}
.thirdSection .existingComments .individualComment{
    width: 98%;
    margin-left: auto;
    margin: 2vh 0;
    border-radius: 1px;
    padding: 1vh;
    border: 1px solid #d3d3d3;
}
.thirdSection .existingComments .individualComment .user{
    font-weight: 600;
    font-size: .9em;
    color: rgba(174, 134, 37, 1);
    text-decoration: wavy;
}
.thirdSection .existingComments .individualComment .comment{
    font-size: 1em;
}
.thirdSection .existingComments .individualComment .reactions{
    width: 99%;
    margin-left: auto;
    display: flex;
    font-size: .6em;
    color: #008080;
}
.thirdSection .existingComments .individualComment .reactions p{
    padding-right: 6vh;
}
.thirdSection .share{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.blog .fillSpace2{
    height: 10vh;
    width:100%;
}

@media only screen and (max-width: 400px){
    .secondSection{
        flex-direction: column-reverse;
    }
    .blogContents{
        display:none;
    }
    .blogBody{
        width: 100%;
    }
    .encloseTitle{
        padding: 2vh 0;
        font-weight: 700 !important;
    }
    .encloseMessage{
        font-size: 1em !important;
    }
    .encloseBlog .thirdSection{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .encloseBlog .thirdSection form{
        width: 98%;
        margin-left: auto;
        margin-right: auto;
  
    }
    .thirdSection form .formInput{
        flex:2;
    }
    .thirdSection form .formInputButton input{
        width: fit-content;
        margin-left: auto;
        font-size: .6em;
        border-radius: 2vh;
    }
    .individualComment .user, .individualComment .comment{
        font-size: .7em !important;
    }
    .individualComment .comment{
        padding: 1vh 0;
    }
    .share{
        width: 100% !important;
        overflow-x: scroll;
    }
    .share::-webkit-scrollbar{
        display: none;
    }
}
