.encloseTextA{
    width: 100vw;
    height: 100vh;
    background: #f3eeee;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.encloseTextA .encloseInputs{
    width: 80%;
    border-radius: 2vh;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.encloseTextA .encloseInputs .encloseIndividualInput{
    border-bottom: 1px solid #d3d3d3;
    width: 100%;
}
.encloseTextA .encloseInputs input{
    width: 100%;
    border: none;
    font-size: 1.25em;
    padding: 1vh;
}
.encloseTextA .encloseTextInputButtons{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}
.encloseTextA .encloseTextInputButtons button{
    border: none;
    margin: 1vh;
    padding: 1vh;
    text-transform: uppercase;
    border-radius: 2px;
}
.encloseTextA .encloseTextInputButtons .save{
    background: #008080;
    color: #fff;
}
.encloseTextA .encloseTextInputButtons .close{
    background: red;
    color: #fff;
}
