@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200;300;400&family=Arimo&family=Bungee&family=Gothic+A1:wght@700&family=Josefin+Sans:wght@700&family=Lato:ital,wght@0,300;0,400;1,300&family=Lora&family=Montserrat:wght@500;600&family=Noticia+Text:wght@700&family=Noto+Sans&family=Open+Sans&family=Orbitron:wght@600&family=PT+Sans+Narrow&family=Questrial&family=Roboto+Condensed:wght@300&family=Roboto:wght@300;400;700;900&family=Ropa+Sans&family=Rubik:wght@400;900&family=Slabo+27px&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');
:root {
  --headline:'Gothic A1', sans-serif;
  --subheader:'Roboto Condensed', sans-serif;
  --body:'Lato', sans-serif;
  --note: 'Arima';
  --extra:'Noto Sans', sans-serif;
  --free:'Bungee', cursive;
  --addh: 'Questrial', 'Lato', sans-serif;
  /* --bg: #f8c69b; */
  /* --bg: #fdcc61; */
  --bg:#FFFDFA;
  --main : #dbaa56;
  --fn : #f7c776;
}
.App::-webkit-scrollbar{
  display: none !important;

}
[contenteditable] {
  outline: 0px solid transparent;
}
.heading{
  font-family: var(--subHeader);
  text-transform: uppercase;
  
}
.subheader{
  font-family: var(--subheader);
}
.body{
  font-family: var(--addH);
}
button{
  font-family: var(--extra);
}
.rightSide .small{
  display: none !important;
}
.App {
  max-width: 98vw !important;
  width: 98vw;
  margin-right: auto;
  margin-left: auto;
  font-family: var(--body);
  background: var(--bg);
  overflow-y: hidden !important;
  line-height: 1.5em;
}
.App .specialButton{
  background: rgba(255, 255, 255, 0.2);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border: 1px solid rgba(255, 255, 255, 0.27);
}
.App .specialButton:hover{
  background: rgba(255, 255, 255, 0.8);
}
.wordSection{
  margin: 0;
  max-width: 100vw;
  width: 98vw;
  margin-left: auto;
  margin-right: auto;
  padding: 10vh 0;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  font-family: var(--note);
  color: #AE8625;
}
.wordSection .encloseWordSection{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 1vh 0;
  line-height: 2.5em;
  text-align: center;
  /* background: #fffcf8; */
}

.wordSection .heading{
  font-size: 1.5em;
  font-weight: 600;
  padding-bottom: 2vh;
}
.wordSection .line{
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  border-top: 2px solid #000;
}
.wordSection .encloseMyButton{ 
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.wordSection .wordSectionBody{
  font-size: 2em;
  padding: 1vh 0;
}
.fillSpace{
  width: 100vw;
  height: 20vh;
}
.tinyScreen{
  display: none;
}
._navbar{
  width: 100%;
}
.dropdownContainer{
  background: transparent;
}
.navbar .leftSide p{
  display: none !important;
} 
.healthyMind .heading{
  line-height: 1em !important;
}
.encloseTop .leftSide .medium-logo,
  .encloseTop .leftSide .small-logo{
    display: none !important;
  }

@media only screen and (min-width: 769px){
  .myMeals .differentPlansp{
    display: none !important;
  }
  .mentor .thementor .first{
    display: none !important;
  }
}
@media only screen and (min-width: 601px){
  .mentor .thementor .first{
    display: none !important;
  }
  
}

@media only screen and (max-width: 992px) {
  .navbar{
    padding: 2vh 0 !important;
  }
  .encloseTop{
    width: 95% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 1vh 0 !important;
  }

  .leftSide{
    flex:1;
    display: flex !important;
    align-items: center !important;
  }
  .leftSide img{
    display: flex !important;
    align-items: center !important;
    width: 70% !important;
  }
  .aside{
    flex:2 !important;
    font-size: .8em !important;
  }
  .encloseTop .leftSide .medium-logo{
    display: flex !important;
  }
  .encloseTop .leftSide .big-logo,
  .encloseTop .leftSide .small-logo{
    display: none !important;
  }
  .middleSide ul{
    width: 100% !important;
    height: auto;
    display: flex;
    justify-content: flex-start !important;
  }
  .rightSide{
    flex: .5;
    display: flex;
    justify-content: center;
  }
  .middleSide ul{
    width: 100%;
  }
  .middleSide ul li{
    font-size: .9em;
    display: block;
    margin: 0 auto;
    border: 1px solid black;
  }
  .mentor .thementor .coachImage img{
    padding: 20vh 0 !important;
  }
  .firstHomeSection{
    width: 90% !important;
  }
  .firstHomeSection .rightSection{
   padding-top: 4em !important;
  }
  .cleanse .encloseList ul li{
    font-size: .8em !important;
  }
  .elaboration .encloseElaboration{
    flex-direction: column !important;
  }
  .elaboration .encloseElaboration .encloseHeading{
    text-align: left !important;
    padding-top: 2vh;
  }
  .elaboration .encloseElaboration .body{
    font-size: 1.25em !important;
  }
  .myMeals .body{
    font-size:  1.25em !important;
  }
  .healthyMind .subHeading{
    font-size: 1.25em !important;
  }
  .healthyMind .subBody{
    font-size: 1.25em !important;
  }
  .blogSection .encloseBlogSection .subTitle{
    font-size: 1.25em !important;
  }
  .blogSection .encloseBlogSection .body{
    font-size: 1.25em !important;
  }
  .contact .encloseContact{
    width: 80% !important;
  }
  .contactIcon .contactSth{
    font-size: 5em !important;
  }
  .shopSection .encloseShopContents{
    min-height: 80vh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2vh;
  }
  .login .encloseLogin{
    width: 50% !important;
  }


  
}
@media only screen and (min-width: 601px) and (max-width: 650px){
  .footer .encloseSecondFooter{
    display: flex;
    flex-direction: column !important;
  }
  .encloseSecondFooter .leftSide{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .encloseSecondFooter .leftSide img{
    width: 50% !important;
    
  }
}
@media only screen and (max-width: 768px) {
  /* add item section */
  .encloseContainer .addItemHeader, .encloseContainer form{
    width: 90% !important;
  }
  /*end of add item section*/
  .mentor .thementor .coachDescription{
    font-size: .8em;
  }
  .navbar{
    width: 99% !important;
    padding: 2vh 0 !important;
  }
  .middleSide ul{
    width: 100% !important;
  }
  .middleSide ul li{
    border: none;
    font-size: .7em;
  }
  .navbar .encloseTop{
    width: 95% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .navbar .leftSide{
    flex:1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .navbar .aside{
    flex:1 !important;
    font-size: .5em !important;
    color: #000 !important;
  }
  .navbar .rightSide{
    flex:1 !important;
    display: flex !important;
    align-items: center !important;
    font-size: 1em !important;
  }
  .navbar .encloseTop{
    padding: 1vh 0 !important;
  }
  .navbar .encloseTop .leftSide .big-logo{
    display: none !important;
  }
  .navbar .encloseTop .leftSide .small-logo{
    display: none !important;
  }
  .navbar .encloseTop .leftSide img{
    width: 80% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar .leftSide p{
    display: none !important;
  }
  .heading{
    font-size: 2em;
    line-height: 1em;
    text-align: center;
  }
  .blogSection .heading{
    text-align: start;
  }
  .bigScreenDifferentPlans{
    display: none !important;
  }
  
  .myMeals .differentPlansp{
    display: flex !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center !important;
    padding: 2vh 0 !important;
  }
  .myMeals button{
    border: none;
    color: #008080;
    background: transparent;
  }

  .differentPlansp .directIcon{
    flex: .5;
    border: none !important;
    background: transparent !important;
  }
  .differentPlansp .section{
    flex:3;
    display: block;
  }
  .differentPlansp .section .mealPhoto{
    width: 100% !important;
  }
  .differentPlansp .section .mealPhoto img{
    width: 100% !important;
  }
  .encloseProgram fieldset{
    width: 80% !important;
  }
  .encloseContact{
    display: block !important;
    border: 1px solid #cca585;
  }
  .encloseContact .contactIcon{
    border-radius: 0 !important;
  }
  .encloseCoaching .coaching{
    width: 80% !important;
  }
  .about .encloseAbout .introSection{
    display: block !important;
  }
  .about .encloseAbout .introSection .firstSection{
    width: 100%;
  }
  .about .encloseAbout .introSection .firstSection p{
    border: none !important;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
  }
  .about .encloseAbout .introSection .secondSection{
    display: none !important;
  }
  .myMeals .differentPlansp{
    display: flex !important;
  }
  .encloseElaboration{
    width: 90% !important;
}
.encloseElaboration .encloseLeftElaboration{
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
}
.encloseElaboration .encloseLeftElaboration .encloseHeading{
    width: 100% !important;
}
.elaboration .encloseElaboration .body{
    width: 100% !important;
    font-size: 1em !important;
    line-height: 1.5em !important;

}
.healthyMind .contentSection{
  width: 90% !important;
}
.healthyMind .contentSection .contentImage{
  flex:2;
}
.healthyMind .contentSection .healthyMindSection{
  flex:1;
}
.healthyMind .healthyMindSection {
  width: 100%;
  padding: 0 !important;

}
.healthyMind .healthyMindSection .subHeading{
  font-size: 1em !important;
  padding-bottom: .2vh !important;

}
.healthyMind .healthyMindSection .subBody{
  font-size: 1em !important;
  padding-top: 0 !important;
 
}
.healthyMind .specialButton{
  margin-top: 0 !important;
}
.encloseReviewTitle .reviewHeader{
  margin-left: auto;
  margin-right: auto;
}

.weHelp .mainHelpRight .encloseMainHelpImage img{
  margin-left: -10vh !important;
  margin-top: 0 !important;
}
.weHelp .rightHelp .rightHelpHeading{
  font-size: 1.5em !important;
}
.weHelp .rightHelp .belowText{
  margin: 0 auto 5vh auto !important;
}
.rightHelp .helpClarityCall{
  font-size: 1em !important;
}
.weHelp .encloseHelpSection .rightHelp .rightHelpBody{
  font-size: 1em !important;
}
.blogSection .blogRight .vitaminBody .subTitle{
  font-size: 1em !important;
}
.blogSection .blogRight .vitaminFoods .subTitle{
  font-size: 1em !important;
}
.blogSection .blogRight .vitaminBody .body,
.blogSection .blogRight .vitaminFoods .body{
  font-size: 1em !important;
  opacity: .8 !important;
}
.sideBar .homeArticle, .sideBar .noborder{
  font-size: .8em !important;
}
.contact .encloseContact .contactIcon{
  padding: 4vh 0;
}
.blogContents .encloseBlogContents{
  width: 100% !important;
}
.blogContents .encloseBlogContents::-webkit-scrollbar{
  display: none !important;
}
.blogContents .encloseBlogContents fieldset{
  width: 90%;
  margin:  0 auto;
  border: 1px solid #AE8625;
  padding: 1vh !important;
}
.blogContents .encloseBlogContents fieldset legend{
  font-size: 1em !important;
  font-weight: 500 !important;
  opacity: .6 !important;
}

.blogContents .encloseBlogAdvertisements{
  border: 1px solid black !important;
}


  
}
@media only screen and (max-width: 600px) {
  .navbar{
    width: 95% !important;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    justify-content: space-between !important;
    padding:.5vh !important;
  }
  .navbar .encloseTop .aside{
    display: none !important;
  }
  .navbar .encloseTop{
    width: 100% !important;

  }
  .navbar .leftSide{
    flex:4 !important;
    text-align: left;
    display: flex;
    align-items: center !important;
  }
  .navbar .encloseTop .leftSide img{
    width: 60% !important;
  }
  .navbar .leftSide .small-logo{
    display: none !important;
  }
  .navbar .leftSide img{
    width: 50%;
    display: flex;
    align-items: center !important;
  
  }
  .navbar .leftSide .big-logo, .navbar .leftside .small-logo{
    display: none !important;
  }
  .navbar .rightSide{
    flex:1;
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .navbar .rightSide .notSmall{
    font-size: .8em !important;
    padding: .5vh 4vh !important;
  }
  .navbar .rightSide .cartIcon{
    display: flex !important;
    justify-content: flex-end;
    color: #000 !important;
  }
  
  .middleSidesixhundred{
    height: fit-content !important;
    display: block !important;
    border-top: 1px solid #cca585;
  }
  .middleSidesixhundred ul{
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
    display: flex;
    justify-content: space-around;
  }
  .middleSidesixhundred ul li{
    font-weight: 500;
    font-size: .8em;
  }
  .navbar .rightSide .cartIcon{
    border: none;
    background: transparent;
    color: #B37A4C;
    font-size: 2em;
  }
  .rightSide .moreIcon{
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: transparent;
  }
  .rightSide .encl{
    border-radius: .1em;
    border: 1px solid #B37A4C;
    color: #B37A4C;
  }
  .navbar .middleSide{
    display: none !important;
  }
  .navbar .leftSide{
    display: flex;
    align-content: flex-start;
  }
  .navbar .leftSide p{
    display: block !important;
  }
  .navbar .rightSide{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
  }
  .navbar .tinyScreen{
    display: block;
  }
  .navbar .encloseHamburgerContents{
    list-style: none;
  }
  /* homepage begins here */
  .homepage{
    position: relative !important;
  }
  .firstHomeSection{
    margin-top: 10vh !important;
  }
  .firstHomeSection .rightSection{
    padding-top: 10vh !important;
    width: 90% !important;
    border-radius: 10em 10em 0 0 !important;
  }
  .encloseWordSection .heading{
    font-size: 1.25em;
    text-align: left;
  }
  .encloseWordSection .wordSectionBody{
    font-size: 1em;
    opacity: 0.7;
  }
  .cleanse .encloseCleanseContent .heading{
    font-size: 2em;
    line-height: 1em;
  }
  .cleanseContent{
    display: block !important;
  }
  .cleanseContent iframe{
    flex:1;
  }
  .cleanseContent ul{
    flex:1 !important;
    opacity: .7;
  }
  .cleanseContent ul li{
    font-size: .8em !important;

  }
  .heading{
    text-align: 2em !important;
    line-height: 1em;
    text-align: center !important;
  }
  .contentSection .contentImage{
    flex: 1;
  }
  .contentSection{
    padding: 4vh 0 0 0 !important;
  }
  .contentSection .contentImage img{
    width: 95%;
  }
  .contentSection .healthyMindSection{
    flex:.5 !important;
  }
  .twiceencloseCleanse .encloseCleanse .heading{
    text-align: start;
  }
  .twiceencloseCleanse  .encloseMyCleanses{
    display: flex !important;
    flex-direction: column !important;
    justify-items: center;
  }
  .twiceencloseCleanse  .encloseMyCleanses .fourteendayCleanse{
    margin-bottom: 8vh;
    width: 100%;
  }
  .enclose14Cleanse{
    background: #fff;
    border-radius: 0 0 2em 2em;
    overflow: hidden !important;
  }
  .twiceencloseCleanse  .encloseMyCleanses .twentyonedayCleanse{
    width: 100%;
    margin-left: 0 !important;
  }
  .healthyMindSection .subHeading{
    font-size: 1.5em;
    font-weight: 500;
  }
  .healthyMindSection .subBody{
    opacity: 0.8;
  }
  .bigScreenDifferentPlans{
    display: none !important;
  }
  .myMeals{
    display: block !important;
    width: 100% !important;
    background: #fff;
  }
  
  .myMeals .body{
    font-size: 1.25em !important;
    line-height: 1em;
    padding: 2vh 0 !important;
  }
  
  .healthyMind .contentSection{
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .healthyMind .healthyMindSection .subBody{
    font-size: 1.25em;
    line-height: 1em;
  }
  .mentor{
    background: #fff;
  }
  .mentor .thementor{
    height: auto !important;
    flex-direction: column !important;
    padding: 0 !important;
  }
  .mentor .thementor .last{
    display: none !important;
  }
  .mentor .thementor .coachDescription{
    background: transparent;
    padding: 2vh 0 !important;
  }
  .mentor .thementor .coachDescription p{
    font-size: 1.25em;
    line-height: 1em;
    opacity: .9;
  }
  .myReviews .encloseMyReviews .bottomsection{
    display: block !important;
  }
  .myReviews .encloseMyReviews .bottomsection .datereviewed{
    font-size: .7em !important;
    margin-left: auto !important;
  }
  .blogSection .sideBar{
    display: none !important;
  }
  .blogSection .encloseBlogSection{
    border: none !important;
  }
  .footer .encloseSecondFooter{
    display: block;
  }
  .encloseSecondFooter .leftSide{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .encloseSecondFooter .leftSide img{
    width: 50% !important;
    
  }
  .footer .rightSide{
    display: block !important;
  }
  .footer .socialSection .socials{
    padding-top: 2vh !important;
  }
  .finalLineFooter .encloseFinalFooter{
    display: flex;
    flex-direction: column-reverse; 
  }
  .finalLineFooter .encloseFinalFooter .footerRight{
    display: flex;
    justify-content: flex-start;

  }
  .finalLineFooter .encloseFinalFooter .footerRight .socials{
    opacity: .8;
    font-size: .8em;
    text-align: start;
    padding-bottom: 1vh;
    display: flex;
    justify-content: space-between;
  }
  .about .encloseAbout .introSection{
    display: block !important;
  }
 
  .about .encloseAbout .introSection .firstSection p{
    border: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
  }
  .about .encloseAbout .introSection .secondSection{
    display: none !important;
  }
  .contact .encloseContact{
    display: block !important;
    border: 1px solid #cca585;
  }
  .contact .encloseContact .contactIcon{
    border-radius: 0 !important;
  }
  .shopContents .mealSection, .shopContents .myCleanse{
    display: block !important;
  }
  .shopContents .mealSection .individualMeal{
    margin-bottom: 2vh !important;
  }
  .shopContents .mealSection .individualMeal .encloseSelect{
    margin-left: 0 !important;
  }
  .shopContents .myCleanse .individulaCleanse{
    margin-bottom: 2vh !important;
  }
  .encloseProgram{
    width: 100% !important;
  }
  .encloseProgram fieldset{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .footerEnclosing{
    border-top: 1px solid #d3d3d3 !important;
  }
}
@media only screen and (max-width: 400px) {
  /* add item section */
  .addItemContainer .encloseContainer{
    min-width: 320px !important;
  }
  .encloseContainer .c_singular{
    display: flex;
    flex-direction:  column !important;
  }
  /*end of add item section*/
  .App{
    width: 100% !important
  }
  .App::-webkit-scrollbar{
    display: none !important;
  }
  .navbar .encloseTop{
    display: flex !important;
  }
  .navbar .encloseTop .leftSide{
    flex:1 !important;
  }
  .navbar .encloseTop .leftSide img{
    width: 90% !important;
  }
  .navbar .encloseTop .rightSide{
    flex: 1 !important;
    display: flex !important;
    align-items: center !important;
  }
  .navbar .leftSide .medium-logo{
    display : none !important;
  }
  .navbar .encloseTop .leftSide .small-logo{
    display : flex !important;
  }
  .navbar .rightSide .notSmall{
    display: none !important;
  }
  .navbar .rightSide .small{
    display: flex !important;
    font-size: 2em !important;
    border: none !important;
    align-self: center;
  }
  .navbar .rightSide .cartIcon,
  .navbar .rightSide .small{
    padding: 0 2vh!important;
  }
  .middleSidesixhundred{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .middleSidesixhundred ul{
    display: flex;
    justify-content: space-around !important;
    overflow-x: scroll;
  }
  .middleSidesixhundred ul::-webkit-scrollbar {
    display: none;
  }
  .middleSidesixhundred ul li{
    font-size: .6em;
    font-weight: 100 !important;
  }
  .firstHomeSection .rightSection{
    border-radius: 8em 8em 0 0 !important; 
    overflow: hidden;
    padding-bottom: 10vh !important;
    margin-top: 10vh !important;
  }
  .rightSection .body{
    overflow: hidden !important;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 5; 
    -webkit-box-orient: vertical;
    margin: 2vh 0;
    padding: 0 !important;
    
    
  }
  .rightSection .wholeness{
    display: none;
  }
  .rightSection button{
    margin-top: 2vh !important;
    width: 80% !important;
    display: block;
    margin: 0 auto;
  }
  .cleanse{
    width: 98% !important;
  }
  .cleanse .encloseCleanseContent .heading{
    font-size: 2.5em;
    line-height: 1em;
  }
  .cleanseContent{
    display: block !important;
  }
  
  .cleanseContent .encloseList ul li{
    font-size: .8em !important;
  }
  .cleanseContent iframe{
    width: 95% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .wholeness{
    display: none;
  }
  .twiceencloseCleanse .encloseCleanse .heading{
    font-size: 2.5em;
    line-height: 1em;
    text-align: start;
  }
  .twiceencloseCleanse  .encloseMyCleanses{
    display: flex !important;
    flex-direction: column !important;
    justify-items: center;
  }
  .twiceencloseCleanse  .encloseMyCleanses .fourteendayCleanse{
    margin-bottom: 8vh;
    width: 100%;
  }
  .enclose14Cleanse{
    background: #fff;
    border-radius: 0 0 2em 2em;
    overflow: hidden !important;
  }
  .twiceencloseCleanse  .encloseMyCleanses .twentyonedayCleanse{
    width: 100%;
    margin-left: 0 !important;
  }
  
  .myMeals .heading{
    font-size: 2.5em;
    line-height: 1em;
  }
  .myMeals .differentPlansp{
    display: flex !important;
  }
  .myMeals .body{
    font-size: 1em !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .myMeals .bigScreenDifferentPlans .bigSection1,
  .myMeals .bigScreenDifferentPlans .bigSection2,
  .myMeals .bigScreenDifferentPlans .bigSection3,
  .myMeals .bigScreenDifferentPlans .bigSection4{
    display: none !important;
  }
  .myMeals .bigScreenDifferentPlans{
    display: none !important;
  }
  .myMeals .bigScreenDifferentPlans .bigSection{
    width:100%;
    background: transparent;
  }
  .myMeals .bigScreenDifferentPlans .bigSection .mealData,
  .myMeals .bigScreenDifferentPlans .bigSection .mealPhoto
  {
    background: #fff;
  }
  .healthyMind{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .healthyMind .heading{
    font-size: 2.5em;
    line-height: 1em;
  }
  .healthyMind .contentSection{
    display: flex;
    flex-direction: column;
  }
  .healthyMind .contentSection .healthyMindSection{
    padding: 1vh 0 !important;
  }
  .mentor{
    width: 20% !important;
    background: #fff !important;
    padding: 1vh !important;
    
  }
  .mentor .last{
    display: none !important;
  }
  .mentor .heading{
    font-size: 2em;
    line-height: 1em;
  }
  .mentor .thementor{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: fit-content;
    padding-top: 0 !important;
  }
  .mentor .thementor .coachImage img{
    width: 90% !important;
    display: block;
    margin: 0 auto;
  }
  .mentor .thementor .coachDescription{
    background: transparent;
    border-radius: none !important;
    font-size: 1.15em !important;
    line-height: 1.5em !important;
    width: 98% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .mentor .thementor .coachDescription p{
    font-size:  1em !important;
  }
  .myReviews .encloseReviewTitle .reviewHeader{
    font-size: 2em;
    line-height: 1em;
    text-decoration: underline;
    border: none !important;
  }
  .myReviews .encloseMyReviews .bottomsection{
    display:block !important;
  }
  .myReviews .encloseMyReviews .bottomsection .datereviewed{
    color: #008080;
    font-size: .7em !important;
  }
  .blogSection .sideBar{
    display: none !important;
  }
  .blogSection .encloseBlogSection{
    border: none !important;
  }
  .footer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .footer .encloseSecondFooter{
    display:flex;
    flex-direction: column !important;
  }
  .footer .encloseSecondFooter .rightSide{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    margin: 0 auto;
  }
  .encloseSecondFooter .leftSide img,
  .encloseSecondFooter .rightSide .first{
    display: block;
    margin: 0 auto;
  }
  .footerEnclosing{
    border-top: 1px solid #d3d3d3 !important;
  }

  .encloseFinalFooter{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .encloseFinalFooter .footerRight .socials{
    width: 100% !important;
    opacity: .8;
    padding-bottom: 1vh !important;
    font-size: .6em !important;
    text-align: start !important;
  }
  .shopSection .shopHeader .shopHeaderContent{
    padding-top: 2vh !important;
  }
  .program .encloseProgram{
    width: 80%;
    padding-top: 0 !important;
  }
  .about{
    padding-top: 0 !important; 
    margin-top: 0 !important;
  }
  .about .encloseAbout .introSection{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
  .about .encloseAbout .introSection .firstSection{
    width: 100% !important;  

  }
  .about .encloseAbout .introSection .firstSection p{
    border: none !important;
    margin-left: auto;
    margin-right: auto;
    padding :1vh 0 !important;
  }
  .about .encloseAbout .introSection .secondSection{
    display: none !important;
  }
  .weHelp{
    padding: 1vh 0 !important;
  }
  .weHelp .encloseHelpSection .leftHelp .mainHelpLeft{
    align-self: center !important;
  }
  .weHelp .encloseHelpSection .leftHelp .mainHelpRight{
    align-self: center;
  }
  .weHelp .encloseHelpSection .leftHelp .mainHelpRight .encloseMainHelpImage img{
    width: 140% !important;
    margin-left: -5vh !important;
    margin-top: 0 !important;
  }
  .weHelp .encloseHelpSection .rightHelp .rightHelpHeading{
    font-size: 1.25em !important;
    font-weight: 550 !important;
    padding-bottom: 4vh !important;
  }
  .weHelp .encloseHelpSection .rightHelp .rightHelpBody .helpClarityCall{
   font-size: .8em !important;
  }
  .weHelp .encloseHelpSection .rightHelp .rightHelpBody .helpClarityInfo{
    font-size: .9em !important;
  }
  .login .encloseLogin{
    width: 95% !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .blogSection .title{
    width: 90% !important;
  }
 
}
@media only screen and (max-width: 320px) {
  .App{
    width: 95vw !important;
  }
  .App::-webkit-scrollbar{
    display: none !important;
  }
  .navbar{
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    min-width: 250px !important;
  }
  .navbar .encloseTop .leftSide{
    flex:1 !important;
  }
  .leftSide img{
    width: 70% !important;
  }
  .navbar .encloseTop .rightSide{
    flex:1 !important;
    font-size: 1em !important;
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .navbar .encloseTop .rightSide .small{
    display: none !important;
  }
  
  .navbar .encloseTop .rightSide .moreIcon, 
  .navbar .encloseTop .rightSide .cartIcon {
    display: flex !important;
    border: none !important;
    margin: 0 !important;
    padding: 0  1vh!important;
    color: #000 !important;
    border-radius: none !important;
  }
  .middleSidesixhundred{
    display:none !important;
  }
  .firstHomeSection, .wordSection, .encloseCleanseContent{
    width: 100% !important;
  }
  .firstHomeSection .rightSection{
    height: 50vh !important;
    min-width: 200px !important;
    border-radius: 0% !important;
    margin-top: 0 !important;
  }
  .firstHomeSection .rightSection .body{
    overflow: hidden;
    display: -webkit-box;
    text-overflow: clip;
    -webkit-line-clamp: 6 !important; 
            line-clamp: 6 !important; 
    -webkit-box-orient: vertical;
    margin: 2vh auto !important;
  }
  .firstHomeSection .leftSection{
    display: none !important;
  }
  .firstHomeSection .rightSection .wholeness{
    display: none;
  }
  .firstHomeSection .rightSection button{
    margin-top: 8vh;
    border-radius: 15em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 1.25em;
  }
  .cleanse{
    width: 100% !important;
    padding: 0 !important;
  }
  .cleanse .encloseCleanseContent{
    width: 70% !important;
  }
  .cleanse .encloseCleanseContent .cleanseContent{
    width: 100% !important;
  }
  .myMeals .differentPlansp{
    width: 100%;
    display: inline-flex !important;
  }
  .myMeals .differentPlansp .section{
    width: 80vw !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 5vh;
    flex-direction: column !important;
  }
  .myMeals .directIcon{
    background: transparent;
    border: none;
    font-size: 2em;
  }
  .healthyMind .heading{
    font-size: 2em;
    line-height: 1em;
    padding-bottom: 1vh !important;
  }
  .healthyMind .contentSection{
    display: flex;
    flex-direction: column;
  }
  .healthyMind .healthyMindSection{
    padding: 2vh 0 !important;
  }
  .healthyMind .healthyMindSection .subHeading{
    font-weight: 500;
    font-size: 1.25em;
  }
  .healthyMind .healthyMindSection .subBody{
    font-size: 1em;
    padding: 1vh 0 !important;
  }
  .myMeals .differentPlansp .section{
    width: 40% !important;
  }
  .mentor{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 2vh 0 !important;
    min-height: fit-content;
  }
  .mentor .thementor{
    width: 90%;
    display: flex;
    flex-direction: column !important;
    padding-top: 0 !important;
    border-radius: 0% !important;
  }
  .mentor .thementor .coachDescription{
    background: transparent;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .mentor .thementor .coachDescription p{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1em !important;
  }
  .mentor .thementor .coachImage{
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
    padding: 2vh 0 !important
  }
  .mentor .thementor .coachImage img{
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .mentor .heading{
    font-size: 2em;
    line-height: 1em;
    padding: 0 !important;
    margin-top: 0 !important;
    width: 100% !important;
    padding-bottom: 2vh !important;
  }
  .mentor .last{
    display: none !important;
  }
  .mentor .belowHeading{
    margin-bottom: 2vh !important;

}
  .eventSection{
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    background-repeat: none;
    background-size: cover;
    display: flex;
    justify-content: center;
    font-size: .8em !important;
  }
  .eventSection p{
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .encloseBlogSection{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .encloseBlogSection .blogLeft{
   width: 30%;
   margin-left: auto;
   margin-right: auto;
   padding: 1vh; 
    
  }
  .myReviews .encloseReviewTitle .reviewHeader{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 2em;
    line-height: 1em;
    text-decoration: underline;
    border-bottom: none;
  }
  .myReviews .reviews .bottomsection{
    display: block !important;
  }

  .blogSection .sideBar{
    display: none !important;
  }
  .blogSection .encloseBlogSection{
    border: none !important;
  }
  .blogRight .vitaminBody, .blogRight .vitaminFoods{
    margin: 1vh 0 !important;
  }
  .blogRight .subTitle{
    font-weight: 600;
  }
  .blogRight .vitaminBody .body{
    opacity: .8;
  }
  .blogRight .vitaminFoods ul{
    margin-top: 0;
    opacity: .8;
    line-height: 1.25em;
    list-style-type: circle;
  }
  .footer{
    width: 100vw !important;
  }
  .rightSide .first .subscribeSection{
    width: 97%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 3vh 0 !important;
    padding-left: 1vh !important;
  
  }
  .encloseSecondFooter{
    width: 90% !important;
  }
  .encloseSecondFooter, .encloseSecondFooter .rightSide{
    display: block !important;
  }
  .encloseSecondFooter .socialSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .encloseSecondFooter .socialSection p{
    font-size: 1em !important;
    font-weight: 600 !important;
    text-align: left;
  }
  .encloseSecondFooter .socialSection .socials{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1em !important;
  }
  .rightSide .footerEnclosing{
    border-top: 1px solid #d3d3d3;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
 .rightSide .footerEnclosing ul{
  font-weight: 800 !important;
  opacity: .8;
 }
 .encloseFinalFooter{
  display: flex;
  flex-direction: row !important;
 }
 .encloseFinalFooter .footerRight{
  flex:1;
  margin-left: auto !important;
  display: flex;
  align-items: flex-end !important;
  justify-content: flex-end !important;
 }

 .encloseFinalFooter .footerLeft{
  flex:1;
 }
  .encloseFinalFooter .footerRight .socials{
    width: fit-content !important;
    flex-direction: column !important;
  }
  .finalLineFooter .encloseFinalFooter .footerRight .socials p{
    color:#000;
    text-transform: uppercase !important;
    font-size: 1em !important;
    padding: .5vh 0 !important;
    margin-left: 0 !important;
    font-weight: 1000 !important;
    opacity: 1 !important;
  }
  .encloseFinalFooter .footerRight .deservesBorder{
    border: none !important;
  }
  .encloseFinalFooter .footerLeft{
    font-size: .8em !important;
  }
  .encloseFinalFooter .footerLeft .footerInitials{
    display: flex;
    flex-direction: column !important;
  }
  .encloseFinalFooter .footerLeft .footerInitials .footerName{
    margin: 0 !important;
    padding: 0 !important;
  }
  .footerLeft .cpyrightTag{
    font-size: .8em !important;
    text-transform: uppercase;
  }
  /* before cleanse */
  .encloseCleanseContent{
    width: 100%;
    font-size: 1em;
    border: 1px solid blue !important;
  }
  .encloseCleanseContent .heading{
    line-height: 1em;
    font-size: 2em;
  }
  .encloseCleanseContent .encloseList ul{
    font-size: .8em !important;
    line-height: .5em !important;
  }
  .twiceencloseCleanse .encloseCleanse .heading{
    font-size: 2em;
    line-height: 1em;
    text-align: start;
  }
  .twiceencloseCleanse  .encloseMyCleanses{
    display: flex !important;
    flex-direction: column !important;
    justify-items: center;
  }
  .twiceencloseCleanse  .encloseMyCleanses .fourteendayCleanse{
    margin-bottom: 8vh;
    width: 100%;
  }
  .enclose14Cleanse{
    background: #fff;
    border-radius: 0 0 2em 2em;
    overflow: hidden !important;
  }
  .twiceencloseCleanse  .encloseMyCleanses .twentyonedayCleanse{
    width: 100%;
    margin-left: 0 !important;
  }
  .encloseTwiceButton{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .bigSection1, .bigSection2, .bigSection3, .bigSection4{
    display: none;
  }
  .bigScreenDifferentPlans{
    display: block !important;
    background: #fff;
  }
  .bigSection .mealPhoto{
    background: #fff;
  }
  .bigSection .mealData{
    background: #fff;
  }
  .myMeals .heading{
    font-size: 2em;
    line-height: 1em;
  }
  .myMeals .body{
    font-size: 1em !important;
    padding: 2vh !important;
  }
  
  .cleanseContent iframe{
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
 
}