.finalLineFooter{
    width: 100%;
    /* background: #fffdc6; */
    background: linear-gradient(to right, rgba(255, 255, 255, .8), rgba(255, 255, 255, .1));
    padding-top: 2vh;
    border-top: 1px solid rgba(174, 134, 37, 0.8);
}
.finalLineFooter .footerLeft{
    flex:1;
    display: block;
}
.finalLineFooter .footerRight{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.finalLineFooter .footerLeft .footerInitials{
    display: flex;
}
.finalLineFooter .footerLeft .footerInitials .footerName{
    padding: 0 1vh;
    color: rgba(174, 134, 37, 0.8);
    text-transform: capitalize !important;
}
.finalLineFooter .footerLeft .cpyrightTag{
    font-size: .8em;
    color: #008080;
}
.finalLineFooter .footerRight .socials{
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
.finalLineFooter .footerRight .socials .deservesBorder{
    margin: 0 1vh;
    border-right: 1px solid black;
    padding-right: 2vh;
    text-transform: uppercase;
}
.deservesNoBorder{
    text-transform: uppercase;
}
.encloseFinalFooter{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}