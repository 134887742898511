@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200;300;400&family=Arimo&family=Bungee&family=Dancing+Script:wght@400..700&family=Gothic+A1:wght@700&family=Josefin+Sans:wght@700&family=Lato:ital,wght@0,300;0,400;1,300&family=Lora&family=Montserrat:wght@500;600&family=Noticia+Text:wght@700&family=Noto+Sans&family=Open+Sans&family=Orbitron:wght@600&family=PT+Sans+Narrow&family=Questrial&family=Roboto+Condensed:wght@300&family=Roboto:wght@300;400;700;900&family=Ropa+Sans&family=Rubik:wght@400;900&family=Slabo+27px&display=swap');
.aboutTextField{
    width: 100%;

}
.encloseAboutTextField{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 5vh 0;
    text-transform: capitalize;
}
.encloseAboutTextField .aboutTextHeading{
    display: flex;
    align-items: center;
}
.encloseAboutTextField .aboutTextHeading p{
    margin-right: 2vh;
    font-family: "PT Sans Narrow", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #008080;
    
}
.encloseAboutTextField .aboutTextHeading .owner{
    font-size: 2em;
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    text-transform: capitalize;
}


.encloseAboutTextField .myTextField{
    width: 100%;
    height: 60vh;
    /* caret-color: transparent; */
    font-size: 1.5em;
    padding: .5vh;
    resize: none;
    background: transparent;

    background-attachment: local;
    background-image:
        linear-gradient(to right, white 10px, transparent 10px),
        linear-gradient(to left, white 10px, transparent 10px),
        repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
    line-height: 31px;
    padding: 8px 10px;
    border: 1px dashed black;
    border-radius: 3vh;
}