.shop{
    width: 100%;
}
.shop p{
    padding: 0;
    margin: 0;
}
.encloseShopContents{
    width: 100%;
    padding-top: 10vh;
}
.shopHeader{
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
    padding: 2vh 0;
    padding-bottom: 4vh;
    text-align: center;
    font-size: 2em;
    display: flex;
    justify-content: center;
    border-bottom: rgba(246,157,60, .8);
    /* background: #fffdc6; */
    color: #AE8625
}
.shopHeader .shopHeaderIcon{
    font-size: 2em;
    padding: 0 !important;
    margin: 0;
    color: rgba(174, 134, 37, 1);
    
}
.shopContents{
    width: 100%;
}

.shopContents .mealPlans{
    width: 100%;
   
}
.mealPlans  .encompass{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.mealPlans  .encompass .mealSection{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.mealPlans .encompass .encloseMeal, .mealPlans .encompass .encloseCleanse{
    display: flex;
    justify-content: flex-end;
    padding: 1vh 0;
}
.mealPlans .encompass .encloseMeal button, .mealPlans .encompass .encloseCleanse button{
    padding: 1vh;
    margin-right: 2vh;
    background: transparent;
    border: none;
    border-radius: 1vh;
    color: #000;
    text-transform: uppercase;
    border: 1px solid #008080;
}
.mealPlans  .encompass  .mealSection .individualMeal{
    flex:1;
    margin: 0 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: .5vh;
    border: 1px solid rgba(246,157,60, .8);;
    overflow: hidden;
}

.mealPlans  .encompass .mealSection .individualMeal img{
    width: 100%;
    display: block;
    margin: 0 auto;
}
.individualMeal .encloseSelect{
   display: block; 
   margin: 0 auto;
}
.individualMeal .encloseSelect select{
    border: 1px solid #008080;
}
.individualMeal button{
    border: none;
    background: rgba(246,157,60, .8);;
    padding: 1vh;
}
.individualMeal p{
    margin: 0;
}
.individualMeal .mealPlanName{
    text-align: center;
}

.encompass .myCleanse{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2vh 0;
}
.encompass .myCleanse .individulaCleanse{
    flex:1;
    border-radius: 2px;
    border: 1px solid #d3d3d3;
    margin:1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.encompass .myCleanse .individulaCleanse p{
    margin:0;
}
.encompass .myCleanse .individulaCleanse img{
    width: 100%;
}
.encompass .myCleanse .individulaCleanse  span{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .8vh 0;
}
.encompass .myCleanse .individulaCleanse  span .price{
    font-weight: 100;
}
.encompass .myCleanse .individulaCleanse  span .buy{
    background: rgba(246,157,60, .8);;
    border: none;
    border-radius: 2px;
    padding: 1vh;
    text-transform: capitalize;
}
.encloseSubs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 5vh;
}
.encloseSubs .sub{
    font-size: 1.25em;
    font-weight: 600;
    padding-bottom: .5vh;
}
.encloseSubs .belowSub{
    width: 2%;
    border: 1px solid #AE8625;
}
@media only screen and (max-width: 400px){
    .shopHeader{
        flex-direction: column;
        padding-bottom: 3vh !important;
    }
    .shopHeader .shopHeaderContent{
        font-size: .7em;
        font-weight: 600;
    }
    .mealSection{
        overflow: scroll;
        display: inline !important;
        border: 1px solid black;
    }
    .mealSection::-webkit-scrollbar{
        display:none;
    }
    .mealSection .individualMeal{
        margin-bottom: 2vh !important;
        
    }

    .myCleanse{
        padding-top: 2vh !important;
        border-top: 1px solid #008080;
    }
    .myCleanse .individulaCleanse .iTitle{
        width:30vw !important;
        padding: 1vh 0;
        font-size: .9em !important;
        font-weight: 500;
    }
    .myCleanse .individulaCleanse .price{
        font-size: .8em;
        flex:1;
    }
    .myCleanse .individulaCleanse .buy{
        font-size: .6em;
        flex:1;
        padding: 1px !important
    }
}


