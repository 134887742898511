.addItemContainer{
    width: 100%;
    
}
.addItemContainer .encloseContainer{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
}
.addItemContainer .encloseContainer .addItemHeader{
    width: 60%;
    border-bottom: 1px dotted #000;
    padding-bottom: 1vh;
}
.addItemContainer .encloseContainer .addItemHeader p{
    font-size: 2em;
    padding: 1vh 0;
}
.addItemContainer .encloseContainer form{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.addItemContainer .encloseContainer form .singularItem{
    display: flex;
    padding: 2vh;
}
.addItemContainer .encloseContainer form .singularItem label{
    margin-right: 2vh;
    flex: 1;
}
.addItemContainer .encloseContainer form .singularItem .singularItemInput{
    flex:4;
    display: flex;
    align-items: flex-start;
}
.addItemContainer .encloseContainer form .singularItem .singularItemInput input{
    width: 70% !important;
    height: 2em;
    border: none;
    border: 1px solid #d3d3d3;
    border-radius: .5vh;
}
.addItemContainer .encloseContainer form .singularItem .radioSection{
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}
.addItemContainer .encloseContainer form .singularItem .radioSection .individualRadioOption{
    display: flex;
    align-items: center !important;
    padding: 0 !important;
}
.radioSection input{
    height:10px !important; 
    width:10px !important; 
    vertical-align: middle !important;
}
.addItemContainer .encloseContainer form .singularItem .singularItemInput textarea{
    width: 70%;
    height: 10em;
    border: none;
    border: 1px solid #d3d3d3;
    border-radius: .5vh;
    resize: none;
}
.addItemContainer .encloseContainer form .inputButton{
    display: flex;

}
.addItemContainer .encloseContainer form .inputButton button{
    padding: 1vh;
    font-size: 2vh;
    text-transform: uppercase;
    margin-right: 2vh;
    flex:1;
}
.addItemContainer .encloseContainer form .singularItem .singularItemInput .imageInput{
    border: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}
.inputButton button{
    font-size: 1em !important;
    font-weight: 500 !important;
    border-radius: .5vh;
}
.inputButton .saveButton{
    background: transparent;
    border: none;
    border: 1px solid #008080;
}
.inputButton .saveButton:hover{
    background: #d3d3d3;
    border: none;
}

.inputButton .postButton{
    border: 1px solid transparent;
    background: #dbaa56;
}
.inputButton .postButton:hover{
    background: #fff;
    border: 1px solid #dbaa56;
}


