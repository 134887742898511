.about{
    width: 80%;
    height: auto;
    padding-bottom: 2vh 0;
    margin: 0 auto;
}
.introSection{
    width: 90%;
    height: auto;
    margin:auto;
    display: flex;
    padding-top: 6vh;
    padding-bottom: 6vh;
    align-items: center!important;
}
.introSection .imageSection{
    flex:1;
    align-content: center;
}
.introSection .imageSection img{
    width: 100%;
    display: block;
    margin: 0 auto;
    padding-bottom: 2vh;
}
.introSection .firstSection{
    flex:1;
}
.introSection .firstSection .encloseFirstSection{
    width: 90%;
    height: auto;

}
.introSection .firstSection p{
    padding: 1vh 2vh;
}
.introSection .firstSection button{
    margin-left: 2vh;
}

.hobbieSection{
    width: 100%;
    padding: 2vh 0;
    border-top: .2px solid #d3d3d3;
}
.encloseHobbies{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 1vh 0;
}
.encloseHobbies div{
    flex: 30vw;
    padding: 1vh;

}
.encloseHobbies div img{
    width: 95%;
    display: block;
    margin: 0 auto;
}
.encloseHobbies div p{
    width: 95% !important;
    text-align: center;
}
.hobbieSection .heading{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5em;
    font-weight: 600;
    opacity: .8 !important;
}
.modifyHobbies{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end !important;
    padding: 1vh;
}
.modifyHobbies button{
    padding: 1vh;
    margin-left: 2vh;
}
